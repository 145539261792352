import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Layout, Parallax, DelayedLink } from '@common'
import { OutboundLink } from "gatsby-plugin-google-analytics"

import {
	page__wrapper,
	page__quote,
	page__parallax,
	page__parallax__row,
	page__parallax__inner,
	page__buttons,
	page__button,
	page__button__alt,
	page__testimonial,
	page__list,
} from './styles/page.module.scss'
const About = ({ pageContext, location }) => {
	const { WP } = useStaticQuery(graphql`
		query {
			WP {
				mediaItemBy(slug: "_DSC9787") {
					sourceUrl
					srcSet
					altText
				}
			}
		}
	`)
	return (
		<Layout location={location} page={pageContext.page}>
			<div className={page__wrapper}>
				<h1 className={page__quote}>
					Ukończenie dowolnego kursu programowania{' '}
					<strong>to dopiero początek</strong> w kierunku pierwszej
					pracy
				</h1>
				<h2>
					Teraz musisz tylko{' '}
					<strong>nauczyć się dobrze pisać kod</strong>
				</h2>
				<h4>Przemek Jóźwiakowski | Github: Przemocny</h4>

				<div
					className={[page__parallax, page__parallax__row].join(' ')}
				>
					<Parallax height={450} size={450} image={WP.mediaItemBy} />
					<h3>
						Chcę być takim mentorem jakiego sam chciałbym mieć, 
						jak zaczynałem swoją przygodę z programowaniem prawie 10 lat temu
					</h3>
				</div>

				<ul className={page__list}>
					<li>
						<h4>8 lat</h4>
						<h6>jako Frontend Developer</h6>
					</li>
					<li>
						<h4>Ponad 50 osób</h4>
						<h6>samodzielnie wprowadziłem do świata Frontendu</h6>
					</li>
					<li>
						<h4>327 zadania</h4>
						<h6>
							z HTML, CSS, JavaScript, React, Jest i innych
							bibliotek frontendowych (ciągle tworzę nowe)
						</h6>
					</li>
					<li>
						<h4>Lubię kawę</h4>
						<h6>oraz pracę zdalną</h6>
					</li>
					<li>
						<h4>5 lat</h4>
						<h6>zakochany w React</h6>
					</li>
					<li>
						<h4>ponad 1 milion</h4>
						<h6>
							wymienionych ze studentami wiadomości na slacku i
							messengerze
						</h6>
					</li>
					<li>
						<h4>od 3 lat</h4>
						<h6>prowadzę programistyczną Akademię Gladiatorów</h6>
					</li>
					<li>
						<h4>Lubię uczyć</h4>
						<h6>i pomagać w rozwijaniu się moim podopiecznym</h6>
					</li>
					<li>
						<h4>Mentor</h4>
						<h6>
							na Facebooku, współpracowałem z Infoshare i Future
							Collars
						</h6>
					</li>
					<li>
						<h4>20 tysięcy</h4>
						<h6>godzin spędzonych ze studentami i kursantami</h6>
					</li>
				</ul>

				<div className={page__buttons}>
					<div className={page__button}>
						<span>&gt;</span>
						<DelayedLink to={'/includes/'}>Czytaj dalej</DelayedLink>
					</div>
					<div className={`${page__button} ${page__button__alt}`}>
						<span>&lt;</span>
						<OutboundLink
							target='_blank'
							href='https://www.facebook.com/localhost40310'
						>
							Sprawdź fanpage
						</OutboundLink>
					</div>
					<div className={`${page__button} ${page__button__alt}`}>
						<OutboundLink
							target='_blank'
							href='https://github.com/Przemocny/zbior-zadan-html-css-js-react'
						>
							Zerknij na repozytorium
						</OutboundLink>
						<span>&gt;</span>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default About
